import * as React                  from "react"

import GalleryTemplate from "../templates/gallery";
import { useLocation } from "@reach/router";

const GalleryPreview = () => {
	const location = useLocation();

	const name = new URLSearchParams(location.search).get('name');

	console.log(name);

	return (<GalleryTemplate pageContext={{
		name: name,
		isPublic: false,
		images: []
	}} />)
}

export default GalleryPreview;


